import { AbstractControl, ValidatorFn } from '@angular/forms';

// To be applied at form level
export function atLeastOneFieldRequiredValidator(...controlNames: string[]): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const values = controlNames.map(name => control.get(name).value);
    const isEmpty = values.every(value => !value);

    if (isEmpty) {
      const error = {};
      controlNames.forEach(name => (error[name] = true));
      return error;
    } else {
      return null;
    }
  };
}
